import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["ignore"];

  connect() {
    this.element.addEventListener("click", this.handleClick.bind(this));
  }

  disconnect() {
    this.element.removeEventListener("click", this.handleClick.bind(this));
  }

  handleClick(event) {
    const slideIn = document.getElementById("slidein-pane");

    if (!slideIn || !slideIn.classList.contains("active-pane")) {
      return;
    }

    if (this.shouldIgnore(event.target) || slideIn.contains(event.target)) {
      return;
    }

    this.closeSlideInPane();
  }

  shouldIgnore(target) {
    return this.ignoreTargets.some((ignoreTarget) =>
      ignoreTarget.contains(target)
    );
  }

  closeSlideInPane() {
    fetch("/actions/*/close", {
      method: "GET",
      headers: { Accept: "text/vnd.turbo-stream.html" },
    })
      .then((res) => res.text())
      .then((html) => {
        document.getElementById("action_pop_out").innerHTML = html;
      });
  }
}
