import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.mountComponent(); // Ensure React mounts on initial load
  }

  mountComponent() {
    window.ReactRailsUJS.mountComponents(this.element);
  }
}
