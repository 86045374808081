import t from "./translate";

const completeAction = async (id, data) => {
  const formData = new FormData();
  formData.append("authenticity_token", window.authToken());
  formData.append("completed_year", data["completed_year"]);
  formData.append("final_cost", data["final_cost"]);
  formData.append("comment", data["comment"]);

  try {
    const response = await fetch(`/actions/${data.id}/complete`, {
      // Does not use API
      method: "PUT",
      body: formData,
      credentials: "same-origin",
    });
    return response.ok;
  } catch (e) {
    return false;
  }
};

const getFilterQueryObject = (filters) => ({
  start_year: filters.startYear,
  end_year: filters.endYear,
  from_total_price: filters.fromTotalPrice,
  to_total_price: filters.toTotalPrice,
  text: filters.text,
  investment_rate: filters.investmentRate,
  status: filters.status,
  category_name: filters.category_name,
  location: filters.location,
  building: filters.building,
  plan_selection_list: filters.planSelectionList,
  tag_list: filters.tag_list,
  tag_ids: filters.tagIds,
  project: filters.project,
  is_energy_saving: filters.isEnergySaving,
  carbon_dioxide_equivalent: filters.carbonDioxideEquivalent,
});

const STATUS_OPTIONS = [
  { value: "planned", label: t("Planerad") },
  { value: "prioritized", label: t("Akut") },
  { value: "deferred", label: t("Eftersatt") },
  { value: "decided", label: t("Beslutad") },
  { value: "in_progress", label: t("Pågående") },
  { value: "completed", label: t("Genomförd") },
  { value: "inactive", label: t("Vilande") },
  { value: "draft", label: t("Utkast") },
];

export { completeAction, getFilterQueryObject, STATUS_OPTIONS };
