import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["password", "submitButton"];

  submitButtonTargetConnected() {
    this.toggleButton();
  }

  toggleButton() {
    this.submitButtonTarget.disabled = this.passwordTarget.value.trim() === "";
  }
}
