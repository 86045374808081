import React from "react";
import { Pie } from "react-chartjs-2";
import {
  CHART_COLORS,
  legendSettings,
  eventSettings,
  tooltipColorSettings,
} from "../lib/chartHelpers";
import { shape, arrayOf, string, number, bool } from "prop-types";
import { sum, map } from "lodash";
import { currency } from "../lib/helpers";

const PieChart = ({
  series,
  height,
  devicePixelRatio,
  alignLegendItems = "center",
  colors = CHART_COLORS,
  showPercentageInLegend = false,
  tooltipLabelCallback = _.bind(currency, _, _, true),
}) => {
  const values = map(series, 1);
  const total = sum(values);
  const labels = map(series, ([label, value]) =>
    showPercentageInLegend
      ? `${label} (${calculatePercentage(value, total)}%)`
      : label
  );
  return (
    <Pie
      data={{
        labels: labels,
        datasets: [
          {
            data: values,
            backgroundColor: colors,
          },
        ],
      }}
      height={height}
      options={options(
        total,
        alignLegendItems,
        devicePixelRatio,
        tooltipLabelCallback
      )}
    />
  );
};

const options = (
  sumOfAllValues,
  alignLegendItems,
  devicePixelRatio,
  tooltipLabelCallback
) => {
  return {
    maintainAspectRatio: false,
    devicePixelRatio: devicePixelRatio,
    ...eventSettings,
    legend: {
      align: alignLegendItems,
      ...legendSettings,
    },
    tooltips: {
      position: "nearest",
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          return [
            data.labels[tooltipItem.index],
            tooltipLabelCallback(dataset.data[tooltipItem.index]),
          ];
        },
        footer: function (tooltipItems, data) {
          const dataset = data.datasets[tooltipItems[0].datasetIndex];
          const value = dataset.data[tooltipItems[0].index];
          return `${calculatePercentage(value, sumOfAllValues)} %`;
        },
      },
      ...tooltipColorSettings,
    },
  };
};

const calculatePercentage = (value, total) =>
  Math.round((1000 * value) / total) / 10;

export default PieChart;

PieChart.prototypes = {
  series: arrayOf(shape([string, number])).isRequired,
  height: number,
  devicePixelRatio: number,
  alignLegendItems: string,
  colors: arrayOf(string),
  showPercentageInLegend: bool,
};
