import { Controller } from "stimulus";
import { qs } from "../lib/helpers";
import PageLoader from "../lib/page_loader";

export default class extends Controller {
  static values = { reloadPath: String };
  static targets = ["planSelect", "indexationField", "outputField"];

  async reload() {
    if (!this.hasSelectedPlan()) {
      return;
    }

    const queryObject = this.buildQueryObject();
    const url = `${this.reloadPathValue}?${qs(queryObject)}`;

    try {
      const res = await fetch(url);
      const html = await res.text();
      await this.updateDOM(html);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  buildQueryObject() {
    return this.indexationFieldTargets
      .concat([this.planSelectTarget])
      .filter((field) => field.value)
      .reduce((acc, field) => {
        acc[field.name] = field.value;
        return acc;
      }, {});
  }

  async updateDOM(html) {
    this.disconnectSelectControllers();
    // Ensure the DOM is updated before the next step
    await new Promise((resolve) => setTimeout(resolve, 0));

    this.outputFieldTargets.forEach((element) => {
      PageLoader.updateDOM(html, `#${element.id}`);
    });
  }

  hasSelectedPlan() {
    return !!this.planSelectTarget.value;
  }

  outputFieldTargetConnected(element) {
    if (!element.id) {
      throw new Error("Output fields must have an ID");
    }
  }

  disconnectSelectControllers() {
    // Disconnect Select controllers to ensure they are reconnected properly
    this.outputFieldTargets.forEach((element) => {
      element
        .querySelectorAll("[data-controller='select']")
        .forEach((select) => {
          select.dataset.controller = "";
        });
    });
  }
}
