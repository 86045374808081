import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import MaintenancePlanSelect from "./MaintenancePlanSelect";
import PageLoader from "../lib/page_loader";

const RailsMaintenancePlanSelect = ({
  initialValue,
  formElementName,
  options,
}) => {
  const [values, setValues] = useState(initialValue);
  useURLAndPageLoadEffect(formElementName, values);

  const selectedOptions = options.filter((option) =>
    values.includes(option.value)
  );
  const onChange = (selectedOptions) => {
    setValues((selectedOptions || []).map((option) => option.value));
  };

  return (
    <>
      <MaintenancePlanSelect
        values={selectedOptions}
        onChange={onChange}
        options={options}
      />

      {values.map((value) => (
        <input type="hidden" name={formElementName} value={value} key={value} />
      ))}
    </>
  );
};

const useURLAndPageLoadEffect = (formElementName, values) => {
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    searchParams.delete(formElementName);
    for (const value of values) {
      searchParams.append(formElementName, value);
    }
    url.search = searchParams;

    PageLoader.load(url, PageLoader.Action.PUSH);
  }, [formElementName, values]);
};

export default RailsMaintenancePlanSelect;

RailsMaintenancePlanSelect.propTypes = {
  initialValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  formElementName: PropTypes.string.isRequired,
  options: MaintenancePlanSelect.propTypes.options,
};
