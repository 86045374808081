import React from "react";
import PropTypes from "prop-types";
import t from "../lib/translate";
import Select, { components } from "react-select";

const MaintenancePlanSelect = ({ onChange, values, options }) => {
  const addCursorPointerStyle = (provided) => ({
    ...provided,
    cursor: "pointer",
  });

  const sortedOptions = Array.from(options).sort(getOptionComparator(values));

  return (
    <Select
      id="maintenance-plan-select"
      options={sortedOptions}
      value={values}
      onChange={onChange}
      menuPortalTarget={document.querySelector("body")}
      noOptionsMessage={({ inputValue }) => t("Inga resultat")}
      isMulti={true}
      placeholder={t("Sök på fastighet, fastighetstagg eller organisation...")}
      styles={{
        container: (provided) => ({ ...provided, flexGrow: 1 }),
        menuPortal: (provided) => ({ ...provided, zIndex: 2050 }),
        clearIndicator: addCursorPointerStyle,
        dropdownIndicator: addCursorPointerStyle,
        multiValueRemove: addCursorPointerStyle,
      }}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isSearchable={true}
      getOptionLabel={OptionLabel}
      filterOption={isOptionMatchingSearchString}
      components={{
        ValueContainer,
        Option,
        MultiValue,
      }}
    />
  );
};

const getOptionComparator = (selectedOptions) => {
  const typeOrder = ["facility", "organization", "facility_tag"];

  return (a, b) => {
    const aSelected = selectedOptions.includes(a);
    const bSelected = selectedOptions.includes(b);

    if (aSelected && !bSelected) {
      return -1;
    } else if (!aSelected && bSelected) {
      return 1;
    }

    if (a.type !== b.type) {
      return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
    }

    return a.label.localeCompare(b.label, "sv");
  };
};

const isOptionMatchingSearchString = (option, inputValue) => {
  if (!inputValue) {
    return true;
  }

  const label = option.data.label.toLowerCase();
  return inputValue
    .toLowerCase()
    .split(" ")
    .every((word) => label.includes(word));
};

const OptionLabel = (props) => {
  const optionTypes = {
    organization: { icon: "sitemap", label: t("Organisation") },
    facility: { icon: "building", label: t("Fastighet") },
    facility_tag: { icon: "tag", label: t("Fastighetstagg") },
  };
  const optionType = optionTypes[props.type];

  return (
    <span>
      <strong>
        <i className={`fa fa-${optionType.icon}`} /> {optionType.label}
      </strong>
      : {props.label}
    </span>
  );
};

const MultiValue = components.MultiValue;
MultiValue.displayName = "MultiValue";

const Option = ({ ...props }) => {
  return (
    <div className="plan-selection-option">
      <components.Option {...props} className="plan-selection-option-inner">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={(e) => null}
        />{" "}
        <div className="plan-selection-option-label">{props.label}</div>
      </components.Option>
    </div>
  );
};

const ValueContainer = ({ children, ...props }) => {
  const maxValues = 10;
  let count = 0;
  let childsToRender = React.Children.toArray(children).filter((child) => {
    if (child.type.displayName === MultiValue.displayName) {
      count++;
      return count <= maxValues;
    }
    return true;
  });

  if (count > maxValues) {
    const lastValueIndex = childsToRender.findLastIndex(
      (child) => child.type.displayName === MultiValue.displayName
    );

    childsToRender.splice(
      lastValueIndex + 1,
      0,
      <div className="plan-selection-more-selections" key="more-values">
        <span>
          +{count - maxValues} {t("fler")}
        </span>
      </div>
    );
  }

  return (
    <components.ValueContainer {...props}>
      {childsToRender}
    </components.ValueContainer>
  );
};

export default MaintenancePlanSelect;

const optionType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

MaintenancePlanSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(optionType).isRequired,
  options: PropTypes.arrayOf(optionType).isRequired,
};
