import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "hiddenField"];

  updateHiddenField() {
    this.hiddenFieldTarget.value = this.checkboxTarget.checked ? "1" : "0";
  }

  updateCheckbox() {
    this.checkboxTarget.checked =
      this.hiddenFieldTarget.value === "1" ||
      this.hiddenFieldTarget.value === "true";
  }
}
