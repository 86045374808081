/*global $ */

const initializeTooltip = () => {
  document.querySelectorAll('[data-toggle="tooltip"]').forEach((element) => {
    $(element).tooltip();
  });
};

window.addEventListener("load", initializeTooltip);

// When replacing content with turo stream we won't trigger a full page reload
// so we need to reinitialize the tooltips. This event is triggered before
// the new content is rendered. We wait a bit to make sure the new content is
// in the DOM before initializing the tooltips.
document.addEventListener("turbo:before-stream-render", () => {
  setTimeout(initializeTooltip, 10);
});
