import t from "./translate";

const prettyRound = (number, breakPoints = [1000, 100, 10, 1]) => {
  for (const breakPoint of breakPoints) {
    if (number > breakPoint) {
      return Math.round(number / breakPoint) * breakPoint;
    }
  }

  return number;
};

const currency = (input, round = false) => {
  if (typeof input !== "number") {
    return input;
  }

  const currency = t("kr");
  const number = round ? prettyRound(input) : Math.round(input);
  return (
    number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " " + currency
  );
};

const emissions = (input) => {
  if (typeof input !== "number") {
    return input;
  }
  const unit = t("kg CO₂e");

  return (
    prettyRound(input, [100, 10, 1])
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
    " " +
    unit
  );
};

const qs = (params, prefix) => {
  let str = [];

  Object.keys(params).forEach((key) => {
    let param = prefix ? prefix + "[" + key + "]" : key;
    if (Array.isArray(params)) {
      param = prefix ? prefix + "[]" : key;
    }
    const value = params[key];
    if (value !== null && typeof value === "object") {
      str.push(qs(value, param));
    } else {
      str.push(
        encodeURIComponent(param) +
          "=" +
          encodeURIComponent(value === undefined ? "" : value)
      );
    }
  });

  return str.join("&");
};

const classSet = (...args) => {
  const classObject = args.reduce((obj, arg) => {
    if (Boolean(arg) === false) {
      return obj;
    }
    if (typeof arg === "object") {
      return Object.assign(obj, arg);
    }
    return Object.assign(obj, { [arg]: true });
  }, {});

  const classNames = Object.keys(classObject)
    .map((className) => (classObject[className] ? className : null))
    .filter((className) => Boolean(className));

  return classNames.join(" ");
};

const escapeHTML = (unsafe) =>
  unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");

export { currency, emissions, prettyRound, qs, classSet, escapeHTML };
