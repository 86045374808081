import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "hidden"];

  connect() {
    this.checkboxTarget.indeterminate = this.hiddenTarget.value === "";
  }

  updateHiddenField() {
    this.hiddenTarget.value = this.checkboxTarget.checked ? "1" : "0";
  }
}
