import { Controller } from "stimulus";
import PageLoader from "../lib/page_loader";

export default class extends Controller {
  static targets = ["list"];
  static values = {
    reloadPath: String,
  };

  submit(e) {
    e.preventDefault();
    const form = e.target.closest("form");

    this.postForm(form).then((response) => {
      if (response.ok) {
        if (this.reloadPathValue) {
          this.fetchWithTurbo(this.reloadPathValue);
        } else {
          this.reloadList();
          form.reset();
        }
      } else {
        console.error("Error submitting form", response);
      }
    });
  }

  postForm(form) {
    return fetch(form.action, {
      method: "POST",
      body: new FormData(form),
    });
  }

  fetchWithTurbo(url) {
    fetch(url, {
      method: "GET",
      headers: { Accept: "text/vnd.turbo-stream.html" },
    })
      .then((res) => res.text())
      .then((html) => {
        document.getElementById("action_pop_out").innerHTML = html;
      });
  }

  reloadList(e) {
    const showAll = e?.params?.showall;
    let url = new URL(
      this.reloadPathValue || window.location.href,
      window.location.origin
    );
    if (showAll) {
      url.searchParams.set("show_all", true);
    }

    if (this.reloadPathValue) {
      this.fetchWithTurbo(url);
    } else {
      fetch(url)
        .then((res) => res.text())
        .then((html) => {
          history.pushState(null, "", url.href);
          PageLoader.updateDOM(html, `#${this.listTarget.id}`);
        });
    }
  }
}
