import React from "react";
import { arrayOf, bool, string, shape, object, number } from "prop-types";
import { Bar } from "react-chartjs-2";
import {
  getColor,
  getLabel,
  sumCostLabel,
  tooltipColorSettings,
  legendSettings,
  eventSettings,
  seriesToChartData,
  costLabel,
} from "../lib/chartHelpers";
import { currency, emissions } from "../lib/helpers";
import t from "../lib/translate";

const formatters = {
  currency,
  emissions,
};

const TimeBarChart = ({
  series,
  devicePixelRatio,
  stacked = true,
  maxLegendItems = 100,
  reverseStackingOrder = false,
  height = 300,
  preferredColors = {},
  alignLegendItems = "center",
  formatter = "currency",
}) => {
  const datasets = series.map((serie, index) => ({
    id: serie.id,
    label: getLabel(serie.label),
    backgroundColor: getColor(index, getLabel(serie.label), preferredColors),
    borderWidth: 0,
    maxBarThickness: 50,
    data: seriesToChartData(serie.data),
  }));

  if (reverseStackingOrder) {
    datasets.reverse();
  }

  const getDatasetKey = (dataset) => {
    return dataset.id ? dataset.id : dataset.label;
  };

  const formattedValue = (value) => {
    const formatterFunction = formatters[formatter];
    return formatterFunction ? formatterFunction(value) : value;
  };

  return (
    <div style={{ height, marginTop: 20 }}>
      <Bar
        datasetKeyProvider={getDatasetKey}
        data={{ datasets }}
        options={{
          layout: {
            padding: 5,
          },
          maintainAspectRatio: false,
          devicePixelRatio: devicePixelRatio,
          legend: {
            display: series.length > 1 && series.length < maxLegendItems,
            reverse: reverseStackingOrder,
            align: alignLegendItems,
            ...legendSettings,
          },
          ...eventSettings,
          tooltips: {
            position: "nearest",
            callbacks: {
              title: (items) =>
                `${t("År")} ${new Date(items[0].label).getFullYear()}`,
              label: (tooltipItem, data) =>
                costLabel(tooltipItem, data, formatters[formatter]),
              afterBody: (tooltipItems, data) => {
                if (data.datasets.length > 1 && stacked) {
                  return sumCostLabel(tooltipItems[0].index, data);
                }
              },
            },
            ...tooltipColorSettings,
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
                type: "time",
                time: {
                  unit: "year",
                },
                distribution: "linear",
                offset: true,
                ticks: {
                  autoSkip: true,
                  source: "data",
                },
                stacked: stacked,
              },
            ],
            yAxes: [
              {
                stacked: stacked,
                ticks: {
                  callback: (value) => formattedValue(value),
                  maxTicksLimit: 4,
                },
              },
            ],
          },
        }}
      />
    </div>
  );
};

export default TimeBarChart;

TimeBarChart.propTypes = {
  series: arrayOf(shape({ data: object.isRequired, label: string })).isRequired,
  devicePixelRatio: number,
  stacked: bool,
  alignLegendItems: string,
};
