import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import t from "../lib/translate";
import Loader from "./Loader";
import TimeBarChart from "./TimeBarChart";

const FacilitiesPageAnalysis = ({ analysis }) => {
  const [activeTab, setActiveTab] = useState("by_action_type");

  if (!analysis) {
    return <Loader />;
  }

  const groupedActionTypeCosts = analysis.action_type;
  const groupedFacilityCosts = analysis.yearly_facility_costs;
  const groupedTagCosts = analysis.facility_tag_costs;

  const assignLabelAndDataToObject = (item) =>
    Object.assign({}, item, {
      label: item.name,
      data: item.costs,
    });

  const yearlyActionTypeChartData = groupedActionTypeCosts.map(
    assignLabelAndDataToObject
  );
  const yearlyChartData = groupedFacilityCosts.map(assignLabelAndDataToObject);
  const yearlyTagChartData = groupedTagCosts.map(assignLabelAndDataToObject);

  const tabs = [
    {
      key: "by_action_type",
      label: t("Utgiftstyp och år"),
      component: (
        <div data-cy="graph-organization-by-action-type">
          <TimeBarChart
            series={yearlyActionTypeChartData}
            reverseStackingOrder={true}
          />
        </div>
      ),
    },
    {
      key: "by_facilities",
      label: t("Fastighet och år"),
      component: (
        <div data-cy="graph-organization-by-facilities">
          <TimeBarChart series={yearlyChartData} reverseStackingOrder={true} />
        </div>
      ),
    },
    {
      key: "by_tags",
      label: t("Tagg och år"),
      component: (
        <div data-cy="graph-organization-by-tags">
          <TimeBarChart stacked={false} series={yearlyTagChartData} />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="flex flex-wrap gap-40 align-items-center flex-jc-spb">
        <div className="flex align-items-center">
          <p className="mb-0 mr-8 fw-medium">{t("Välj graf:")}</p>
          <Dropdown>
            <Dropdown.Toggle
              className="react-dropdownbtn"
              variant=""
              id="dropdown-basic"
            >
              {tabs.find((tab) => tab.key === activeTab).label}
            </Dropdown.Toggle>
            <Dropdown.Menu className="react-dropdownmenu">
              {tabs.map((tab) => (
                <Dropdown.Item
                  as="li"
                  key={tab.key}
                  onSelect={() => setActiveTab(tab.key)}
                  active={activeTab === tab.key}
                >
                  {t(tab.label)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div data-cy={`graph-organization-${activeTab}`}>
        {tabs.find((tab) => tab.key === activeTab).component}
      </div>
    </>
  );
};

export default FacilitiesPageAnalysis;
