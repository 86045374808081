import React from "react";
import PieChart from "./PieChart";
import { array, arrayOf } from "prop-types";
import t from "../lib/translate";
import colors from "../../assets/stylesheets/colors.scss";

const FacilitiesSustainabilityPieChart = ({ series }) => {
  const buildingShare = (input) => {
    if (typeof input !== "number") {
      return input;
    }

    return `${input} ${t(input === 1 ? "byggnad" : "byggnader")}`;
  };

  const getChartColorsFromEnergyClasses = (energyClasses) => {
    const colorMap = {
      A: colors.energy_class_a,
      B: colors.energy_class_b,
      C: colors.energy_class_c,
      D: colors.energy_class_d,
      E: colors.energy_class_e,
      F: colors.energy_class_f,
      G: colors.energy_class_g,
      [t("Saknas")]: colors.soft_gray,
    };

    return energyClasses.map((energyClass) => {
      return colorMap[energyClass];
    });
  };

  return (
    <div className="analysis-pie-chart">
      <PieChart
        series={series}
        colors={getChartColorsFromEnergyClasses(series.map((item) => item[0]))}
        tooltipLabelCallback={buildingShare}
      />
    </div>
  );
};

export default FacilitiesSustainabilityPieChart;

FacilitiesSustainabilityPieChart.propTypes = {
  series: arrayOf(array).isRequired,
};
